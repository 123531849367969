var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"tag":"section","fluid":""}},_vm._l((_vm.ligen),function(l){return _c('base-material-card',{key:l._id,attrs:{"icon":"fa-table","title":l.name,"color":"red"}},[_c('v-data-table',{attrs:{"items":l.results,"headers":[
        { text: 'Platz', value: 'place', sortable: false, align: 'center' },
        { text: 'Mannschaft', value: 'name', sortable: false, align: 'left' },
        { text: 'TP', value: 'tp', sortable: false, align: 'center' },
        { text: 'Punkte', value: 'final', sortable: false, align: 'center' },
        { text: 'WKs', value: 'wk', sortable: false, align: 'center' } ],"hide-default-footer":"","items-per-page":-1,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.final",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("float2_0")(item.final))+" ")]}}],null,true)})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }